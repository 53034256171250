#header {
  padding: 1rem .5rem 3rem;
  width: 100%;
  border-bottom: 1px solid $gray-color;

  .color-bar {
    height: 16px;
    overflow: hidden;

    img {
      float: right;
    }
  }

  .navbar {
    margin-top: 1rem;
  }

  .logo {

    .navbar-brand {
      white-space: nowrap;
    }

    .logo-text {
      display: inline-block;
      vertical-align: middle;
      padding-left: 15px;
    }

    h1 {
      color: $dark-color;
      font-size: 32px;
      margin: 0 0 5px;
      font-weight: 400;
    }

    h2 {
      color: $secondary-color;
      font-size: 16px;
      margin: 0;
      overflow: hidden;

      @include breakpoint(md) {
        font-size: 14px;
      }

      @include breakpoint(sm) {
        font-size: 11px;
      }
    }


    img {
      width: 200px;
      max-width: 120px;
      vertical-align: middle;
    }
  }

  .langswitcher {

    white-space: nowrap;
    padding: 0;
    margin: 5px 0;

    li {
      margin-left: 0;
      padding: 0;
    }
    li:after {
      content: '|';
    }
    li:last-child:after {
      content: '';
    }
    li a {
      padding: 5px;
      color: $gray-color-dark;

      &:hover {
        color: $dark-color;
      }

      &.active {
        font-weight: inherit;
        color: $link-color;
      }
    }
  }

  // Fixed Header solution
  .header-fixed & {
    position: fixed;
    top: 0;
    z-index: 200;

    ~ #body {
      margin-top: 6rem;
    }
  }
}
