// Core variables
$dark-color: #6C6C6C;
$light-color: #fff;

// Global
$html-font-size: 10px;
$html-line-height: 1.428571429;
$body-bg: $light-color;
$body-font-color: #888;
$font-size: 1.6rem;
$font-size-sm: 1.2rem;
$font-size-lg: 1.6rem;

// Fonts
// Credit: https://www.smashingmagazine.com/2015/11/using-system-ui-fonts-practical-guide/
$base-font-family: 'Ubuntu';
$fallback-font-family: "Helvetica Neue", sans-serif;
$cjk-font-family: $base-font-family, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Hiragino Kaku Gothic Pro", Meiryo, "Malgun Gothic", $fallback-font-family;
$body-font-family: $base-font-family, $fallback-font-family;

// Sizes
$layout-padding: 1rem;
$layout-padding-lg: 1.5rem;
$control-min-width: 18rem;
$border-radius: .2rem;
$border-width: .1rem;

// Colors
// Core colors
$primary-color: #E20216;
$primary-color-dark: darken($primary-color, 3%);
$primary-color-light: lighten($primary-color, 3%);
$secondary-color: #648699;
$secondary-color-dark: darken($secondary-color, 3%);
$secondary-color-light: lighten($secondary-color, 3%);
$tertiary-color: #325E6E;

$link-color: $primary-color;
$link-color-dark: darken($link-color, 10%);

// Gray colors
$gray-color: lighten($dark-color, 40%);
$gray-color-dark: darken($gray-color, 20%);
$gray-color-light: lighten($gray-color, 20%);

$border-color: lighten($gray-color-light, 3%);
$border-color-dark: #999;
$bg-color: lighten($dark-color, 66%);
$bg-color-dark: darken($bg-color, 3%);
$bg-color-light: $light-color;

// Control colors
$control-color-success: #32b643;
$control-color-warning: #ffb700;
$control-color-error: #e85600;

// Other colors
$code-color: #e06870;
$highlight-color: #ffe9b3;

$silver-chalice-color: #9E9E9E;
$mine-shaft-color: #242424;
$empty-color: #848586;

$monza-color: #E20216;
$glacier-color: #76ACC2;
$nile-blue-color: #18414C;
$nevada-color: #646566;


// Responsive breakpoints
$size-xs: 480px;
$size-sm: 600px;
$size-md: 840px;
$size-lg: 960px;
$size-xl: 1280px;
$size-2x: 1440px;

// Z-index
$zindex-0: 1;
$zindex-1: 100;
$zindex-2: 200;
$zindex-3: 300;
$zindex-4: 400;
